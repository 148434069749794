import request from '../utils/request'

const Api = {
    ParkingList: 'parking/list',
    ParkingDetail: 'parking/detail'

}

/**
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function ParkingList (data) {
    return request({
        url: Api.ParkingList,
        method: 'POST',
        params:data
    })
}


/**
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function ParkingDetail (data) {
    return request({
        url: Api.ParkingDetail,
        method: 'POST',
        data:data
    })
}

